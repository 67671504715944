import { createContext, useContext, useEffect, useState } from "react";
import { createEventBusHelper } from "../utils/EventBusHelper";
import EventTypes from "../consts/EventTypes";
import * as CookieUtil from "../utils/CookieUtil";
import * as Apis from "../apis/Apis";
import * as ApiErrorHandler from "../apis/ApiErrorHandler";
import { CSpinner } from "@coreui/react";
import DialogManager from "../views/dialog/DialogManager";
import makeRoleMenu from "../common/function/makeRoleMenu.js";
import { detectMobileBrowser } from "../utils/detectMobileBrowser.js";
import { useNavigate } from "react-router-dom";
import { setFirebaseUserProperties } from "../lib/firebase.lib";

const EventBusHelper = createEventBusHelper();

const AppContext = createContext();

export function useAppContext() {
  const contextValue = useContext(AppContext);
  if (!contextValue) {
    throw new Error("AppContext must be called from within an AppProvider");
  }

  return contextValue;
}

const AppProvider = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState({
    adminId: "",
    adminNo: "",
    userName: "",
  });
  const [authToken, setAuthToken] = useState(CookieUtil.getAuthorization());
  const [roles, setRoles] = useState([]);
  const [nav, setNav] = useState([]);
  const [roleObj, setRoleObj] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  const setAuthTokenWrapper = (data) => {
    setAuthToken(data);
    CookieUtil.setAuthorization(data);
    // local 환경에서 firebase를 실행시키지 않음
    if (window.location.protocol === "http:") {
      return;
    }
  };

  useEffect(() => {
    checkUserAgent();
    if (authToken) {
      getAuth();
    } else {
      setLoaded(true);
      navigate("/login");
      console.log("no Token");
    }

    EventBusHelper.addListener(EventTypes.App.Logout, () => {
      setAuthTokenWrapper(null);
      navigate("/login");
    });

    return () => {
      EventBusHelper.removeAllListeners();
    };
  }, []);

  const checkUserAgent = () => {
    setIsMobile(detectMobileBrowser());
  };

  const getAuth = async () => {
    setLoaded(false);
    try {
      const authToken = await LoginByTokenFetch();
      getRoleMenu(authToken);
    } catch (e) {
      ApiErrorHandler.allCode(e);
    } finally {
      setLoaded(true);
    }
  };

  const LoginByTokenFetch = async () => {
    setLoading(true);
    try {
      const { data } = await Apis.loginByToken();
      const { adminId, adminNo, userName, authorization, comCd } = data;
      setFirebaseUserProperties(comCd, adminNo, userName);
      setUser({ adminId, adminNo, userName });
      setAuthTokenWrapper(authorization);
      return authorization;
    } catch (e) {
      ApiErrorHandler.allCode(e);
    } finally {
      setLoading(false);
    }
  };

  const getRoleMenu = async (auth) => {
    let result;
    setLoading(true);
    try {
      result = await Apis.getRoleMenuAfterTokenLogin(auth);
    } catch (e) {
      ApiErrorHandler.allCode(e);
    } finally {
      setLoading(false);
    }
    const [navMenu, role] = makeRoleMenu(result.data);
    setRoleObj({ ...role });
    setNav([...navMenu]);
    setRoles(result.data);
  };

  const renderLoading = () => (
    <div className={"renderLoading"}>
      <CSpinner />
    </div>
  );

  const store = {
    setLoading,
    loading,
    user,
    setUser,
    authToken,
    setAuthToken: setAuthTokenWrapper,
    roles,
    roleObj,
    setRoleObj,
    nav,
    setNav,
    isMobile,
  };
  return (
    <AppContext.Provider value={store} {...props}>
      {loaded && props.children}
      <DialogManager />
      {loading && renderLoading()}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
