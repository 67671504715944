import { QueryClient } from "@tanstack/react-query";

function queryErrorHandler(error: unknown): void {
  // error is type unknown because in js, anything can be an error (e.g. throw(5))
  const title =
    error instanceof Error
      ? error.toString().replace(/^Error:\s*/, "")
      : "error connecting to server";
}

export function generateQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        onError: queryErrorHandler,
        staleTime: 180000, // 1초 : 1000 = 3분 * 60 : 180000
        cacheTime: 300000, // 1초 : 1000 = 5분 * 60 : 300000
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        // 위 옵션을 반드시 권하고 싶지는 않음
      },
      mutations: {
        onError: queryErrorHandler,
      },
    },
  });
}

export const queryClient = generateQueryClient();
