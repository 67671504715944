import moment from "moment-timezone";

export function get(key) {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];

    let j = 0;
    while (j < cookie.length && cookie[j] === " ") {
      j++;
    }

    let m = 0;
    while (j < cookie.length && m < key.length && cookie[j] === key[m]) {
      m++;
      j++;
    }

    if (m === key.length && cookie[j] === "=") {
      return cookie.substring(j + 1);
    }
  }
  return null;
}

export function set(key, value, exmins) {
  let newCookie = `${key}=${value}; path=/`;

  if (exmins) {
    const date = new Date();
    date.setTime(date.getTime() + exmins * 60 * 1000);
    newCookie += "; expires=" + date.toUTCString();
  }

  document.cookie = newCookie;
}

export function reset(key) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

export function setAuthorization(value) {
  if (value) {
    set("Authorization", value, 360);
  } else {
    reset("Authorization");
  }
}

export function getAuthorization() {
  return get("Authorization");
}

export function setActiveLocale(value) {
  set("locale", value);
}

export function changeLocaleCookie(value) {
  setActiveLocale(value);
  window.location.reload();
}

export function getActiveLocale() {
  let locale = get("locale");
  if (!locale) {
    let language = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    language =
      language ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage;
    let shortLanguage = language;

    if (shortLanguage.indexOf("-") !== -1) {
      shortLanguage = shortLanguage.split("-")[0];
    }

    if (shortLanguage.indexOf("_") !== -1) {
      shortLanguage = shortLanguage.split("_")[0];
    }
    locale = shortLanguage || "en";
    setActiveLocale(locale);
  }
  return locale;
}

export function getActiveTimezone() {
  let timezone = get("timezone");
  if (!timezone) {
    timezone = moment.tz.guess(true);
  }
  setActiveTimezone(timezone);
  return timezone;
}

export function setActiveTimezone(value) {
  let newCookie = `timezone=${value}; path=/`;
  document.cookie = newCookie;
}

export function changeTimezoneCookie(value) {
  setActiveTimezone(value);
  window.location.reload();
}
