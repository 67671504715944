import { createContext, useState } from "react";

const RemoteControlContext = createContext({});

const RemoteControlProvider = (props) => {
  const [isShow, setIsShow] = useState(false);
  const [remoteControlList, setRemoteControlList] = useState([]);
  const [loadingPerVehicle, setLoadingPerVehicle] = useState([]);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState("");

  const store = {
    loadingPerVehicle,
    setLoadingPerVehicle,
    isShow,
    setIsShow,
    remoteControlList,
    setRemoteControlList,
    isRequestLoading,
    setIsRequestLoading,
    selectedBtn,
    setSelectedBtn,
  };

  return (
    <RemoteControlContext.Provider value={store}>
      {props.children}
    </RemoteControlContext.Provider>
  );
};
export { RemoteControlProvider, RemoteControlContext };
