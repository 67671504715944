import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import { AppProvider } from "./contexts/AppContext";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "./react-query/queryClient";
import { RemoteControlProvider } from "./contexts/RemoteControlContext";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const ReportViewer = React.lazy(() =>
  import("./views/iot/iot-test/report-viewer/ReportViewer"),
);
// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// Pages
const Login = React.lazy(() => import("./views/login/Login"));

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <RemoteControlProvider>
          <AppProvider>
            <React.Suspense fallback={loading}>
              <Routes>
                <Route
                  path="/iot/test/report-viewer"
                  element={<ReportViewer />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/*" element={<TheLayout />} />
              </Routes>
            </React.Suspense>
          </AppProvider>
        </RemoteControlProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
