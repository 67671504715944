import qs from "qs";
import isEmpty from "lodash.isempty";
import * as CookieUtil from "../utils/CookieUtil";

function request(method, url, accessToken, body = {}) {
  const options = {
    method: method,
    headers: {},
  };
  if (method === "GET") {
    options.headers = { "Content-Type": "application/json" };
  }

  if (method !== "GET") {
    if (body instanceof FormData) {
      options.body = body;
    } else {
      options.headers = { "Content-Type": "application/json" };
      options.body = JSON.stringify(body);
    }
  }

  if (accessToken) {
    options.headers.Authorization = accessToken;
  }

  options.headers["Timezone-Name"] = CookieUtil.getActiveTimezone();
  options.headers["Accept-Language"] = CookieUtil.getActiveLocale();

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(async (res) => {
        const contentType = res.headers.get("content-type");
        switch (contentType) {
          case "application/json": {
            const data = await res.json();
            if (res.status === 200 || res.status === 201) {
              resolve(data);
              if (accessToken) {
                CookieUtil.setAuthorization(accessToken);
              }
            } else {
              reject({ ...data, status: res.status });
            }
            break;
          }
          case "ms-vnd/excel": {
            const header = res.headers.get("Content-Disposition");
            const parts = header?.split("=");
            const fileName = parts ? parts[1] : null;
            const blob = await res.blob();
            resolve({ fileName, blob });
            break;
          }
          case "application/xml": {
            resolve(res.url);
            break;
          }
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
            resolve(res);
            break;
          }
          case "application/octet-stream": {
            resolve(res.url);
            break;
          }
          case "text/plain": {
            const header = res.headers.get("Content-Disposition");
            const parts = header?.split("=");
            const fileName = parts ? parts[1] : null;
            const blob = await res.blob();
            resolve({ fileName, blob });
            break;
          }
          default:
            reject({ message: "Unknown type : " + contentType });
            break;
        }
      })
      .catch(async (error) => {
        if (error.json) {
          try {
            reject(await error.json());
          } catch (e) {
            reject(e);
          }
        } else {
          reject(error);
        }
      });
  });
}

export function requestByTempToken(method, url, accessToken, body = {}) {
  const options = {
    method: method,
    headers: {},
  };
  if (method === "GET") {
    options.headers = { "Content-Type": "application/json" };
  }

  if (method !== "GET") {
    if (body instanceof FormData) {
      options.body = body;
    } else {
      options.headers = { "Content-Type": "application/json" };
      options.body = JSON.stringify(body);
    }
  }

  if (accessToken) {
    options.headers.Authorization = accessToken;
  }

  options.headers["Timezone-Name"] = CookieUtil.getActiveTimezone();
  options.headers["Accept-Language"] = CookieUtil.getActiveLocale();

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(async (res) => {
        const contentType = res.headers.get("content-type");
        switch (contentType) {
          case "application/json": {
            const data = await res.json();
            if (res.status === 200 || res.status === 201) {
              resolve(data);
            } else {
              reject({ ...data, status: res.status });
            }
            break;
          }
          default:
            reject({ message: "Unknown type : " + contentType });
            break;
        }
      })
      .catch(async (error) => {
        if (error.json) {
          try {
            reject(await error.json());
          } catch (e) {
            reject(e);
          }
        } else {
          reject(error);
        }
      });
  });
}

class ApiRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get = (url, accessToken, params) => {
    if (!isEmpty(params)) {
      url = `${url}?${qs.stringify(params, { indices: false })}`;
    }
    return request("GET", this.baseUrl + url, accessToken);
  };
  post = (url, accessToken, body) => {
    return request("POST", this.baseUrl + url, accessToken, body);
  };
  put = (url, accessToken, body) => {
    return request("PUT", this.baseUrl + url, accessToken, body);
  };
  patch = (url, accessToken, body) => {
    return request("PATCH", this.baseUrl + url, accessToken, body);
  };
  delete = (url, accessToken, body) => {
    return request("DELETE", this.baseUrl + url, accessToken, body);
  };
}

export default ApiRequest;
