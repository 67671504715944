import React, { useContext } from "react";
import {
  CButton,
  CCardBody,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import styled from "styled-components";
import Draggable from "react-draggable";
import { tt } from "../utils/LangUtil.js";
import { AppContext } from "../contexts/AppContext.js";

const NewModalPage = ({
  show,
  title,
  message,
  left,
  right,
  onClose,
  danger,
  size,
}: any) => {
  const { isMobile } = useContext(AppContext);
  return (
    <Draggable disabled={isMobile}>
      <CustomCModal show={show} onClose={onClose} centered color={danger}>
        {title && (
          <CModalHeader>
            <CModalTitle>{tt(title)}</CModalTitle>
          </CModalHeader>
        )}
        {message && (
          <CModalBody className="hello p-2">
            <CCardBody>{tt(message)}</CCardBody>
          </CModalBody>
        )}
        <CustomCFooter>
          {left && (
            <CButton
              block
              color="dark"
              className="font-weight-bold cancel_btn"
              onClick={() => onClose(left)}
            >
              {tt(left)}
            </CButton>
          )}
          {right && !danger && (
            <CButton
              color="primary"
              className="confirm_btn"
              onClick={() => onClose(right)}
            >
              {tt(right)}
            </CButton>
          )}
          {danger && (
            <CButton
              block
              color="danger"
              className="font-weight-bold"
              onClick={() => onClose(danger)}
            >
              {danger}
            </CButton>
          )}
        </CustomCFooter>
      </CustomCModal>
    </Draggable>
  );
};

export default NewModalPage;

const CustomCFooter = styled(CModalFooter)`
  border-top: none;
  justify-content: center;

  button {
    font-size: 1rem;
    width: 200px;
    height: 40px;
  }

  button:nth-child(1):nth-last-child(2),
  button:nth-child(1):nth-last-child(2) ~ button {
    width: 45%;
  }
`;

const CustomCModal = styled(CModal)`
  > div {
    max-width: 400px;
  }

  .modal-body {
    display: flex;
    align-items: center;
  }
`;
