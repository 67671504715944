import lang from "../lang/lang.js";
import * as CookieUtil from "./CookieUtil.js";

const activeLocale = CookieUtil.getActiveLocale();

function format(str, params) {
  let result = str;

  if (params) {
    Object.keys(params).forEach((key) => {
      let value = params[key];
      const template = new RegExp("{" + key + "}", "gm");

      if (value === null || value === undefined) {
        value = "";
      }
      result = result.replace(template, String(value));
    });
  }

  return result;
}

// 기존코드
// export function tt(key, params) {
//   let value = null;
//   try {
//
//     value = lang[key][activeLocale];
//     // 글자가 등록이 안되어 있으면 에러가 난다.
//
//   } catch (e) {
//     // console.error(e)
//     console.warn(`text not found : ${key}`);
//   }
//   if (!value) {
//     value = key;
//     // console.warn(`text not found : ${key}`);
//   }
//   return format(value, params);
// }

export function tt(key, params) {
  let value = null;
  if (!key || !lang[key]) {
    // translate하지 않으면 여기로 빠짐
    // lang[key]가 undefined 이므로 바로 에러떨어짐
  } else {
    value = lang[key][activeLocale];
  }

  if (!value) {
    value = key;
    // console.warn(`text not found : ${key}`);
  }
  return format(value, params);
}
